.container {
  width: 100%;
  padding: 0 20rem;

  @include devices(tablet) {
    padding: 0 2rem;
  }
}

.items-highlighter {
  color: var(--highlight-primary);
  font-family: 'eurostileBold';
}

.carousel {
  position: relative;
  .background {
    background-color: transparent !important;
  }
  .carousel__container {
    height: 65rem !important;

    @include devices(tablet) {
      height: 40rem !important;
    }

    .carousel__container--item {
      height: 100% !important;
      background-color: transparent !important;
      box-shadow: none !important;
      width: 45rem !important;
      border-radius: 0 !important;

      @include devices(tablet) {
        width: 30rem !important;
      }

      img {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        width: 100% !important;
        box-shadow: none !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }

      .carousel-text-cont {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-360deg, rgba(0, 0, 0, 0.6), transparent);

        .text {
          font-size: 12rem;
          font-weight: 600;
          color: var(--text-primary);
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
  }
}

table {
  th {
    font-size: 1.3rem;
    padding: 1rem 1rem !important;
  }
  td {
    padding: 1rem 1rem !important;
    font-size: 1.3rem !important;
  }
}

.css-13cymwt-control {
  background-color: transparent !important;
  border-radius: 3rem !important;
  border: 1rem solid var(--highlight-primary);
  border-width: 0.2rem !important;
  border-color: var(--highlight-primary) !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.4rem;
  color: var(--highlight-primary) !important;
  min-height: 2rem !important;

  &::placeholder {
    color: var(--highlight-primary) !important;
    font-size: 1.2rem !important;
  }
}

.css-t3ipsp-control {
  background-color: transparent !important;
  border-radius: 3rem !important;
  border: 1rem solid var(--highlight-primary);
  border-width: 0.2rem !important;
  border-color: var(--highlight-primary) !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.4rem;
  color: var(--highlight-primary) !important;
  min-height: 2rem !important;

  &::placeholder {
    color: var(--highlight-primary) !important;
    font-size: 1.2rem !important;
  }
}

.css-1dimb5e-singleValue {
  color: var(--highlight-primary) !important;
  font-weight: 600;
}

.css-1jqq78o-placeholder {
  color: var(--highlight-primary) !important;
  font-weight: 600;
  font-size: 1.2rem !important;
}

.edit-chapter-modal {
  .modal-content {
    width: 200rem;
  }
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
}

.react-confirm-alert {
  background: #f5f5f5;
  padding: 2rem 4rem;
  border-radius: 1rem;

  .custom-ui {
    .submit-btn {
      background-color: #004869;
      border-radius: 4rem;
      outline: none !important;
      padding: 0.6rem 4rem;
      border: none !important;
      color: #fff;
      font-size: 1.6rem;
      margin-top: 1rem;

      &:disabled {
        background-color: var(--bg-3) !important;
        color: #004869 !important;
      }
    }

    .cancel-btn {
      background-color: transparent;
      border-radius: 4rem;
      outline: none !important;
      padding: 0.6rem 4rem;
      border: 0.1rem solid #004869 !important;
      color: #004869;
      font-size: 1.6rem;
      margin-right: 1rem;
      margin-top: 1rem;

      @include devices(tablet) {
        margin-top: 1rem;
      }

      &:hover {
        background-color: #004869 !important;
        color: #fff !important;
      }
    }
  }
}

.no-data {
  font-size: 1.3rem;
  color: var(--text-primary);

  @include devices(tablet) {
    width: 80%;
    text-align: center;
  }
}

.no-data-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .no-data-logo {
    height: 40rem;

    @include devices(tablet) {
      height: 30rem;
    }
  }
}

.search-cont {
  margin: 2rem 0;

  @include devices(tablet) {
    width: 100% !important;
  }

  .form-control {
    background-color: var(--main-bg);
    border: 0.1rem solid hsl(0, 0%, 80%) !important;
    outline: none !important;
    padding: 1rem 2rem !important;
    font-size: 1.4rem;
    font-weight: 600;
    box-shadow: none !important;
    border-radius: 20rem;
    color: var(--highlight-primary);
    width: 100%;

    &::placeholder {
      color: var(--bg-4);
    }

    @include devices(mobile) {
      font-size: 1.2rem;
    }
  }
}

.Toastify__toast-body {
  font-size: 1.4rem;
}

.styles-module_whatsappButton__tVits {
  height: 50px !important;
  width: 50px !important;
  bottom: 2.5rem !important;

  @include devices(tablet) {
    height: 40px !important;
    width: 40px !important;
    bottom: 2.5rem !important;
    right: 1.5rem !important;
  }
}

.styles-module_whatsappButton__tVits:after {
  height: 50px !important;
  width: 50px !important;

  @include devices(tablet) {
    height: 40px !important;
    width: 40px !important;
  }
}

.share-your-feedback {
  position: fixed;
  font-size: 1rem;
  z-index: 99999999999;
  bottom: 0;
  right: 0;
  font-weight: 600;
  background-color: var(--highlight-primary);
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  color: white;

  @include devices(tablet) {
    right: 0;
    bottom: 0;
  }
}
