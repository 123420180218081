.auth-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--bg-2);

  @include devices(tablet) {
    flex-direction: column;
  }

  .auth-left-cont {
    background-color: var(--main-bg);
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/auth-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @include devices(tablet) {
      display: none;
    }
  }

  .auth-right-cont {
    width: 40%;
    background-color: var(--main-bg);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .logo-cont {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
      flex-direction: column;

      .logo {
        height: 8rem;
        margin-bottom: 1rem;
      }

      .slogan {
        height: 1.8rem;
      }
    }

    @include devices(tablet) {
      width: 100%;
      background-color: var(--bg-2);
      align-items: center;
      justify-content: flex-start;
      margin: 1rem 0 0 0;
    }

    .header {
      font-size: 2.2rem;
      margin-bottom: 0.5rem;
      font-weight: 200;
      color: var(--text-primary);
      text-align: center;
      margin-bottom: 2rem;
    }

    .auth-form-cont {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include devices(tablet) {
        width: 100%;
        height: 97%;
      }

      .register-form-cont {
        height: 75rem;
        overflow: auto;
        padding: 4rem 2rem !important;

        @include devices(tablet) {
          height: 100%;
        }
      }

      .login-form-cont {
        height: 48rem;
        overflow: auto;
        justify-content: center;
        padding: 2rem !important;

        @include devices(tablet) {
          height: 85%;
        }
      }

      .form-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #eaeef1;
        padding: 4rem 2rem !important;
        border-radius: 3rem;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include devices(tablet) {
          width: 95% !important;
          border-radius: 1rem;
          background-color: transparent;
        }

        .form-group {
          margin: 1rem 0rem;
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;

          .prefix {
            position: absolute;
            left: 6%;
            font-size: 1.6rem;
            top: 1.6rem;
            font-weight: 600;
          }

          .warning {
            color: var(--highlight-error);
            margin: 0.5rem 1rem;
            font-size: 1.3rem;
          }

          @include devices(mobile) {
            width: 100% !important;
          }

          label {
            font-size: 1.3rem;
            margin-left: 1rem;
            font-weight: 600;
            margin-bottom: 1rem;
            color: var(--text-primary);
          }

          .form-control-mobile {
            background-color: var(--white);
            border: 0.2rem solid var(--highlight-primary) !important;
            outline: none !important;
            padding: 1.5rem 5rem !important;
            font-size: 1.4rem !important;
            font-weight: 600;
            box-shadow: none !important;
            border-radius: 6rem;
            color: var(--highlight-primary);

            @include devices(tablet) {
              padding: 1.5rem 5rem !important;
            }

            &::placeholder {
              color: #eee;
            }

            @include devices(mobile) {
              font-size: 2rem;
            }
          }

          .form-control {
            background-color: var(--white);
            border: 0.2rem solid var(--highlight-primary) !important;
            outline: none !important;
            padding: 1.5rem 2rem;
            font-size: 1.4rem !important;
            font-weight: 600;
            box-shadow: none !important;
            border-radius: 6rem;
            color: var(--highlight-primary);

            &::placeholder {
              color: #777;
            }

            @include devices(mobile) {
              font-size: 2rem;
            }
          }
        }

        .new-user {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2rem;

          .content {
            font-size: 1.4rem;
            text-align: center;
            color: var(--text-primary);

            .highlight {
              color: var(--text-primary);
              text-decoration: underline;
              font-size: 1.4rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              cursor: pointer;
              margin-left: 0.5rem;
            }
          }
        }

        .css-13cymwt-control {
          &::placeholder {
            color: #777 !important;
          }
        }

        .css-t3ipsp-control {
          &::placeholder {
            color: #777 !important;
          }
        }

        .auth-btn {
          border-radius: 3rem;
          width: 100%;
          height: 5rem;
          border: 0.2rem solid var(--gradient-1);
          color: var(--text-white) !important;
          font-size: 2rem;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: linear;
          background-color: var(--gradient-1) !important;
          margin-top: 3rem;
          cursor: pointer;
          transition: 0.5s all;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.2rem;

          @include devices(mobile) {
            height: 4.5rem;
            font-size: 2rem;
            width: 100% !important;
          }

          &:hover {
            width: 100%;
            border: 0.2rem solid var(--gradient-1);
            background-color: var(--gradient-1) !important;
            color: var(--main-bg) !important;
          }
        }
      }
    }
  }
}
