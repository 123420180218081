.coming-soon-cont {
  height: 100%;
  background-color: var(--bg-1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .img-1 {
    height: 35rem;
    width: 35rem;

    @include devices(tablet) {
      width: 80%;
      height: 45%;
    }
  }

  .coming-soon-text {
    font-family: 'eurostileBold';
    font-size: 6.2rem;
    color: var(--text-primary);
    font-style: italic;
    margin-top: 10rem;

    @include devices(tablet) {
      margin-top: 5rem;
      font-size: 4rem;
    }
  }
}
