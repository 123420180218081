.profile-cont {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    color: var(--highlight-primary);
    font-weight: 600;
  }

  .profile-sub-cont {
    width: 30%;
    background-color: var(--main-bg);
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    border-radius: 1rem;
    height: 80%;
    padding: 5rem 2rem;

    @include devices(tablet) {
      width: 90%;
    }

    .form-cont {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 4rem 1rem !important;
      border-radius: 3rem;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @include devices(tablet) {
        width: 100% !important;
        border-radius: 1rem;
      }

      .form-group {
        margin: 1rem 0rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        .prefix {
          position: absolute;
          left: 6%;
          font-size: 1.6rem;
          top: 1.6rem;
          font-weight: 600;
        }

        .warning {
          color: var(--highlight-error);
          margin: 0.5rem 1rem;
          font-size: 1.3rem;
        }

        @include devices(mobile) {
          width: 100% !important;
        }

        label {
          font-size: 1.3rem;
          margin-left: 1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: var(--text-primary);
        }

        .form-control {
          background-color: var(--white);
          border: 0.2rem solid var(--highlight-primary) !important;
          outline: none !important;
          padding: 1.2rem 2rem;
          font-size: 1.4rem !important;
          font-weight: 600;
          box-shadow: none !important;
          border-radius: 6rem;
          color: var(--text-primary);

          @include devices(tablet) {
            padding: 1rem 2rem;
          }

          &::placeholder {
            color: #777;
          }

          @include devices(mobile) {
            font-size: 2rem;
          }
        }
      }

      .new-user {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        .content {
          font-size: 1.4rem;
          text-align: center;
          color: var(--text-primary);

          .highlight {
            color: var(--text-primary);
            text-decoration: underline;
            font-size: 1.4rem;
            font-weight: 600;
            letter-spacing: 0.1rem;
            cursor: pointer;
            margin-left: 0.5rem;
          }
        }
      }

      .auth-btn {
        border-radius: 3rem;
        width: 100%;
        height: 4rem;
        border: 0.2rem solid var(--gradient-1);
        color: var(--text-white) !important;
        font-size: 1.6rem;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        background-color: var(--gradient-1) !important;
        margin-top: 3rem;
        cursor: pointer;
        transition: 0.5s all;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        margin-right: 1rem;

        @include devices(mobile) {
          height: 4.5rem;
          font-size: 2rem;
          width: 100% !important;
        }

        &:hover {
          width: 100%;
          border: 0.2rem solid var(--gradient-1);
          background-color: var(--gradient-1) !important;
          color: var(--main-bg) !important;
        }
      }

      .logout-btn {
        border-radius: 3rem;
        width: 100%;
        height: 4rem;
        border: 0.2rem solid var(--bg-5);
        color: var(--text-white) !important;
        font-size: 1.6rem;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        background-color: var(--bg-5) !important;
        margin-top: 3rem;
        cursor: pointer;
        transition: 0.5s all;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        margin-right: 1rem;

        @include devices(mobile) {
          height: 4.5rem;
          font-size: 2rem;
          width: 100% !important;
        }

        &:hover {
          width: 100%;
          border: 0.2rem solid var(--bg-5);
          background-color: var(--bg-5) !important;
          color: var(--main-bg) !important;
        }
      }
    }
  }
}
