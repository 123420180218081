@font-face {
  font-family: 'eurostile';
  src: local('Lato'), url(../../assets/fonts/Eurostile.ttf) format('opentype');
}

@font-face {
  font-family: 'eurostileBold';
  font-weight: 900;
  src: local('Lato'),
    url(../../assets/fonts/EurostileBold.ttf) format('opentype');
}

@font-face {
  font-family: 'eurostileNormal';
  src: local('Lato'),
    url(../../assets/fonts/EurostileNormal.ttf) format('opentype');
}

@font-face {
  font-family: 'eurostileOblique';
  src: local('Lato'),
    url(../../assets/fonts/EurostileOblique.ttf) format('opentype');
}
