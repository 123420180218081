@import './create_masters';

.admin-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10rem 2rem 2rem 2rem;
  height: 100%;
  background-color: #eaeef1;

  .left-cont {
    width: 13%;
    background-color: var(--main-bg);
    height: 100%;
    border-radius: 1rem;
    padding: 1rem 2rem 4rem 2rem;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    span {
      font-size: 1.4rem;
      margin-bottom: 2rem;
      cursor: pointer;
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid var(--bg-3);

      &:hover {
        color: var(--gradient-1);
        font-weight: 600;
      }
    }

    .active-span {
      color: var(--gradient-1);
      font-weight: 600;
    }
  }

  .admin-contents-cont {
    width: 86%;
    background-color: var(--main-bg);
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;

    .css-13cymwt-control {
      background-color: transparent !important;
      border-radius: 3rem !important;
      border: 1rem solid var(--highlight-primary);
      border-width: 0.2rem !important;
      border-color: var(--highlight-primary) !important;
      padding: 0.2rem 1rem !important;
      font-size: 1.4rem;
      color: var(--highlight-primary) !important;

      &::placeholder {
        color: var(--highlight-primary) !important;
        font-size: 1.2rem !important;
      }
    }

    .css-t3ipsp-control {
      background-color: transparent !important;
      border-radius: 3rem !important;
      border: 1rem solid var(--highlight-primary);
      border-width: 0.2rem !important;
      border-color: var(--highlight-primary) !important;
      padding: 0.2rem 1rem !important;
      font-size: 1.4rem;
      color: var(--highlight-primary) !important;

      &::placeholder {
        color: var(--highlight-primary) !important;
        font-size: 1.2rem !important;
      }
    }

    .css-1dimb5e-singleValue {
      color: var(--highlight-primary) !important;
      font-weight: 600;
    }

    .css-1jqq78o-placeholder {
      color: var(--highlight-primary) !important;
      font-weight: 600;
      font-size: 1.2rem !important;
    }

    .form-container {
      display: flex;
      margin-bottom: 2rem;
      background-color: var(--bg-2);
      padding: 2rem;
      border: 0.1rem solid var(--bg-3);
      border-radius: 1rem;
      align-items: flex-end;

      .form-group {
        width: 25%;
        margin-right: 1rem;

        .add-labe-cont {
          background-color: var(--bg-4);
          border-radius: 2rem;
          padding: 1rem;
        }

        .label-cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          label {
            font-size: 1.3rem;
            font-weight: 600;
            color: var(--highlight-primary);
          }
        }

        .array-form-controls {
          display: flex;
          align-items: center;
          margin-top: 2rem;
          justify-content: space-between;
          .icon {
            font-size: 3rem;
          }
        }

        .form-control {
          background-color: var(--main-bg);
          border: 0.1rem solid hsl(0, 0%, 80%) !important;
          outline: none !important;
          padding: 1rem !important;
          font-size: 1.4rem;
          font-weight: 600;
          box-shadow: none !important;
          border-radius: 0.5rem;
          color: var(--highlight-primary);
          width: 100%;

          &::placeholder {
            color: var(--bg-4);
          }

          @include devices(mobile) {
            font-size: 2rem;
          }
        }
      }
    }

    .subject-form-container {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      border: 0.1rem solid hsl(0, 0%, 80%);
      padding: 2rem;

      .form-group {
        width: 100%;
        margin-bottom: 2rem;
        margin-right: 0;

        .label-cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          label {
            font-size: 1.3rem;
            font-weight: 600;
            color: var(--highlight-primary);
          }
        }

        .array-form-controls {
          display: flex;
          align-items: center;
          margin-top: 2rem;
          justify-content: space-between;
          .icon {
            font-size: 3rem;
          }
        }

        .form-control {
          background-color: var(--white);
          border: 0.2rem solid var(--highlight-primary) !important;
          outline: none !important;
          padding: 1rem 2rem !important;
          font-size: 1.4rem;
          font-weight: 600;
          box-shadow: none !important;
          border-radius: 6rem;
          color: var(--highlight-primary);

          &::placeholder {
            color: var(--highlight-primary);
          }

          @include devices(mobile) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .indexing {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    background-color: var(--highlight-primary);
    box-shadow: var(--box-shadow);
    color: var(--text-white);
    margin-bottom: 2rem;
    padding: 0.8rem;
    font-weight: 600;
    border-radius: 0.5rem;
  }

  .btn-cont {
    width: 100%;
    background-color: var(--main-bg);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0 0 1rem 1rem;
    padding: 1rem 0;

    .submit-btn {
      background-color: var(--highlight-primary);
      border-radius: 4rem;
      outline: none !important;
      padding: 1rem 4rem;
      border: none !important;
      color: var(--text-white);
      font-size: 1.6rem;

      &:disabled {
        background-color: var(--bg-4) !important;
        color: var(--text-primary);
      }
    }
  }
}
