.body--dark {
  --highlight-primary: #004869;
  --highlight-secondary: #fff;
  --highlight-green: #2bb25a;
  --highlight-error: #d8000c;
  --highlight-primary-opaque: 251, 162, 2;
  --highlight-secondary-opaque: 255, 255, 255;
  --bg-1: #f5f5f5;
  --bg-2: #fafafa;
  --bg-3: #cccccc;
  --bg-4: #bbbbbb;
  --bg-5: #9a9a9a;
  --main-bg: #fff;
  --text-primary: #4a4b4b;
  --text-white: #fff;
  --text-primary-rgb: 240, 240, 240;
  --bg-1-rgb: 8, 25, 44;
  --gradient-2: rgba(0, 72, 105, 0.9);
  --gradient-1: #fba202;
  --text-secondary: hsla(0, 0%, 100%, 0.6);
  --box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  --box-shadow-1: 0 2px 5px 0 rgb(0 0 0 / 20%);
  --box-shadow-header: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
