.Header {
  position: fixed;
  top: 0;
  height: 7rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 3rem;
  z-index: 9;
  background-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;

  @include devices(tablet) {
    padding: 4rem 2rem;
  }
}

.navOpened {
  background-color: transparent;
}

.Header-scrolled {
  padding: 6rem;
  transition: all 0.5s ease;
  background: linear-gradient(180deg, #000, transparent);

  @include devices(tablet) {
    padding: 6rem 2rem;
  }

  .Logo {
    .img1 {
      height: 6rem;

      @include devices(tablet) {
        height: 6rem;
      }
    }

    .slogan {
      display: none;
    }
  }
}

.Logo {
  display: flex;
  align-items: center;
  .img1 {
    height: 6rem;
    @include devices(tablet) {
      height: 4rem;
    }
  }

  .slogan {
    margin-left: 1rem;
    border-left: 0.1rem solid var(--highlight-primary);
    img {
      height: 2rem;
      width: 28rem;
      @include devices(tablet) {
        display: none;
      }
    }
  }
}

.Nav {
  position: absolute;
  display: flex;
  right: 3rem;
  width: 15%;
  padding: 2rem 0;
  background: var(--bg-1);
  border-radius: 10px;
  height: auto;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  top: 10rem;
  box-shadow: var(--box-shadow);

  @include devices(tablet) {
    flex-direction: column;
    justify-content: flex-start;
    width: 35%;
    top: 8rem;
  }

  .active {
    transform: scale(1.1);
    color: var(--highlight-primary);
  }
}

.nav-items-cont {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include devices(tablet) {
    width: 100%;
  }
}

.Nav a {
  color: var(--text-primary);
  font-size: 2rem;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin: 1rem 0;

  @include devices(tablet) {
    font-size: 1.4rem;
    margin-right: 0;
    margin: 1rem 0;
  }
}

.Nav a:hover {
  transform: scale(1.1);
  color: var(--gradient-1);
  font-weight: 600;
}

.chapter-name {
  margin-right: auto;
  margin-right: 5rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--gradient-1);

  @include devices(tablet) {
    font-size: 1.2rem;
    margin-right: auto;
    margin-left: 0;
  }

  .chapter-name {
    @include devices(tablet) {
      display: none;
    }
  }
}

.search-filter-cont {
  width: 60%;
  display: flex;
  justify-content: flex-end;

  @include devices(tablet) {
    justify-content: flex-end;
  }

  .form-group {
    width: 65%;
    position: relative;
    margin-right: 2rem;

    @include devices(tablet) {
      position: absolute;
      width: 95%;
      top: 90%;
      left: 2.5%;
      margin-right: 0;
    }

    .close-icon {
      position: absolute;
      right: 5%;
      top: 25%;
      font-size: 2.4rem;
    }

    .drop-down-cont {
      position: absolute;
      width: 100%;
      left: 0;
      min-height: 10rem;
      max-height: 50rem;
      background-color: var(--main-bg);
      box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
      top: 105%;
      border-radius: 2rem;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;

      .dropdown-item {
        display: flex;
        margin: 1rem 0;
        width: 95%;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #eaeef1;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        cursor: pointer;
        border-radius: 0.8rem;
        flex-direction: column;

        .span {
          width: 100%;
          text-align: left;
          margin-bottom: 0.5rem;
          display: flex;
        }

        .search-highlight {
          color: var(--gradient-2);
          font-weight: 600;
          margin-left: 0.5rem;
          text-overflow: ellipsis;
          width: 80%;
          overflow: hidden;
        }
      }
    }

    .form-control {
      background-color: var(--white);
      border: 0.2rem solid var(--highlight-primary) !important;
      outline: none !important;
      padding: 1rem 2rem !important;
      font-size: 1.4rem !important;
      font-weight: 600;
      box-shadow: none !important;
      border-radius: 6rem;
      color: var(--highlight-primary);

      @include devices(tablet) {
        padding: 1rem 2rem !important;
        font-size: 1.2rem !important;
      }

      &::placeholder {
        color: var(--highlight-primary);
      }

      @include devices(mobile) {
        font-size: 2rem;
      }
    }
  }

  .filter-btn {
    padding: 0.5rem 2rem;
    border: 0.1rem solid var(--gradient-1);
    font-size: 1.6rem;
    background-color: var(--gradient-1) !important;
    color: var(--text-white) !important;
    font-weight: 600;
    border-radius: 4rem;
    letter-spacing: 0.2rem;
    margin-right: 1rem;

    &:hover {
      background-color: var(--gradient-1) !important;
      color: var(--text-white) !important;
    }
  }
}

.Burger {
  grid-area: burger;
  padding: 0;
  justify-self: end;
  font-size: 4rem;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
  color: var(--text-primary);
  height: 2rem;

  @include devices(tablet) {
    display: block;
    font-size: 3rem;
  }
}

.Burger:active {
  transform: scale(1.2);
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.hamburger-react {
  margin-right: 4rem;
  @include devices(tablet) {
    margin-right: 0 !important;
  }
  div {
    width: 6rem !important;
    height: 1px !important;

    @include devices(tablet) {
      width: 5rem !important;
    }
  }
}
