html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

#root {
  height: 100%;
}
