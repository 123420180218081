.splash-cont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-1);

  .logo {
    position: absolute;
    height: 12rem;
  }
}
