.home-cont-topics {
  @include devices(tablet) {
    padding-top: 4rem !important;
  }
}

.image-cont-link {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  @include devices(tablet) {
    height: 80%;
  }
}

.pdf-iframe {
  height: 100%;
  width: 100%;

  @include devices(tablet) {
    height: 80%;
  }
}

.home-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 6rem;
  height: 100%;
  background-color: #eaeef1;

  @include devices(tablet) {
    padding-top: 9rem;
  }

  .home-contents-cont {
    width: 97%;
    height: 93%;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    @include devices(tablet) {
      justify-content: center;
    }

    .contents-left {
      width: 18%;
      background-color: var(--main-bg);
      height: 100%;
      border-radius: 1rem;

      @include devices(tablet) {
        display: none;
      }

      .title-cont {
        padding: 0.8rem;
        display: flex;
        flex-direction: column;

        .header {
          font-size: 2.2rem;
          margin-bottom: 0.5rem;
          font-weight: 200;
        }

        .sub-header {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .cards-cont {
        height: 85%;
        padding: 1rem;
        overflow: auto;

        .cards {
          background-color: #eaeef1;
          border-radius: 1rem;
          margin-bottom: 2rem;
          padding: 1.2rem;
          border: 0.2rem solid var(--bg-1);
          cursor: pointer;

          .cards-title {
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--text-primary);
            display: flex;
            align-items: flex-start;

            .title-icon {
              font-size: 2rem;
              margin-right: 1rem;
            }

            span {
              font-size: 1.4rem;
              width: 75%;
            }
          }

          .details-cont {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.5rem;

            .details {
              width: 49%;
              font-size: 1.2rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .cards:hover {
          background-color: var(--highlight-primary);

          .cards-title {
            color: var(--main-bg);
          }

          .details-cont {
            .details {
              color: var(--gradient-1);
              letter-spacing: 0.1rem;
              font-weight: 600;
            }
          }
        }

        .active-cards {
          background-color: var(--highlight-primary);

          .cards-title {
            color: var(--main-bg);
          }

          .details-cont {
            .details {
              color: var(--gradient-1);
              letter-spacing: 0.1rem;
              font-weight: 600;
            }
          }
        }
      }
    }

    .contents-right-video {
      padding: 0 !important;
      @include devices(tablet) {
        height: 65%;
      }
    }

    .contents-right {
      width: 81%;
      background-color: var(--main-bg);
      height: 100%;
      border-radius: 1rem;

      @include devices(tablet) {
        width: 95%;
      }

      .video-player-cont {
        height: 100%;
        width: 100%;

        @include devices(tablet) {
          height: 35%;
        }
      }

      .class-select-cont {
        display: none;
        margin-bottom: 1rem;
        padding: 1rem;

        @include devices(tablet) {
          display: block;
        }

        .chapter-name {
          font-size: 1.3rem;
          text-align: center;
          margin: 1rem 0;
        }
      }

      .tabs-cont {
        border-bottom: 0.11rem solid var(--bg-1);
        display: flex;
        overflow-x: auto;
        padding: 1rem 2rem;
        background-color: var(--highlight-primary);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        /* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
        &::-webkit-scrollbar {
          width: 5px;
          height: 8px;
          background-color: var(
            --highlight-primary
          ); /* or add it to the track */
        }

        /* Add a thumb */
        &::-webkit-scrollbar-thumb {
          background: var(--bg-5);
        }

        @include devices(tablet) {
          padding: 1rem;
        }

        .tabs {
          font-size: 1.2rem;
          font-weight: 600;
          padding-bottom: 1rem;
          cursor: pointer;
          transition: 0.2s all;
          padding: 0.5rem 1.5rem;
          margin-right: 1rem;
          border: 0.15rem solid var(--text-white);
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          color: var(--text-white);
          border-radius: 0.8rem;
          width: 100%;
          text-wrap: nowrap;
          text-align: center;

          @include devices(tablet) {
            font-size: 1.2rem;
          }

          &:hover {
            border: 0.15rem solid var(--gradient-1);
            color: var(--gradient-1);
            font-weight: 600;
          }
        }

        .active-tabs {
          // border: 0.15rem solid var(--gradient-1);
          // color: var(--gradient-1);
          // font-weight: 600;

          background-color: var(--gradient-1) !important;
          color: var(--text-primary) !important;
          font-weight: 600 !important;
          border: 0.15rem solid var(--gradient-1) !important;
        }
      }

      .secondary-cont {
        height: 90%;
        overflow: auto;
        padding: 2rem;

        @include devices(tablet) {
          height: 78%;
          padding: 1rem;
        }

        .title {
          font-size: 1.4rem;
          font-weight: 600;
          text-transform: uppercase;
          border-bottom: 0.11rem solid var(--bg-4);
          width: 15%;
          padding: 1rem 0.5rem;
          display: flex;
          align-items: center;

          @include devices(tablet) {
            width: auto;
          }

          .title-icon {
            margin-right: 1rem;
            font-size: 2.6rem;
            color: var(--text-primary);
          }
        }

        .subjects-cont {
          display: flex;
          flex-wrap: wrap;
          margin: 2rem 0 1rem 0;

          .subject-subject-cards {
            width: 19% !important;

            @include devices(tablet) {
              width: 46% !important;
            }
          }

          .chapter-cards {
            width: 70% !important;
            margin-right: 0 !important;

            @include devices(tablet) {
              width: 100% !important;
            }

            .cards-title {
              color: var(--gradient-2) !important;
            }
          }

          .cards {
            border-radius: 0.8rem;
            margin-bottom: 2rem;
            padding: 1.2rem;
            width: 24%;
            margin-right: 1rem;
            cursor: pointer;
            box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);

            .cards-title-icon {
              font-size: 2rem;
              color: var(--text-primary);
              margin-right: 0.5rem;

              @include devices(tablet) {
                display: none;
              }
            }

            @include devices(tablet) {
              width: 46%;
              padding: 0.8rem;
            }

            .cards-title {
              font-size: 1.4rem;
              font-weight: 700;
              color: var(--gradient-2);
              display: flex;
              align-items: center;
              justify-content: space-between;

              @include devices(tablet) {
                font-size: 1.3rem;
              }

              span {
                width: 95%;
                overflow: hidden;
                white-space: pre-wrap;
              }

              .delete-icon {
                cursor: pointer;
                font-size: 1.8rem;
                margin-left: 0.5rem;

                @include devices(tablet) {
                  display: none;
                }
              }
            }

            .cards-title-tutor {
              color: var(--gradient-2);
            }

            .details-cont {
              display: flex;
              flex-wrap: wrap;
              margin-top: 0.5rem;

              @include devices(tablet) {
                flex-direction: column;
              }

              .details {
                width: 49%;
                font-size: 1.2rem;
                font-weight: 600;
                letter-spacing: 0.1rem;
                color: rgba(0, 0, 0, 0.6);

                @include devices(tablet) {
                  width: 100%;
                }

                @include devices(tablet) {
                  font-size: 1.1rem;
                }
              }
            }
          }

          .cards:hover {
            background-color: var(--highlight-primary);

            .cards-title {
              color: var(--text-white) !important;
            }

            .cards-title-tutor {
              color: var(--text-white) !important;
            }

            .cards-title-icon {
              color: var(--text-white) !important;
            }

            .details-cont {
              .details {
                color: var(--gradient-1);
                letter-spacing: 0.1rem;
                font-weight: 600;
              }
            }
          }

          .active-cards {
            background-color: var(--highlight-primary);

            .cards-title {
              color: var(--text-white);
            }

            .cards-title-tutor {
              color: var(--text-white);
            }

            .cards-title-icon {
              color: var(--text-white);
            }

            .details-cont {
              .details {
                color: var(--gradient-1);
                letter-spacing: 0.1rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.home-configuration-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 1rem;

  .left-cont {
    background-image: url('../../assets/images/content-bg-2.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 70%;
    width: 40%;
    background-color: white;

    @include devices(tablet) {
      display: none;
    }
  }

  .content-dropdowns {
    width: 30%;
    background-color: var(--main-bg);
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include devices(tablet) {
      width: 95%;
      height: 85%;
      margin-top: 5rem;
    }

    h6 {
      font-size: 2.4rem;
      color: var(--highlight-primary);
      font-weight: 600;
    }

    .sub-header {
      font-size: 1.2rem;
      font-weight: 400;
      width: 70%;
      text-align: center;
    }

    .main-form-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50%;
      margin: 3rem 0;

      .form-group {
        width: 85%;
        margin-bottom: 2rem;

        @include devices(tablet) {
          width: 90%;
        }
      }

      .css-b62m3t-container {
        margin-right: 1rem;
      }

      .css-13cymwt-control {
        background-color: transparent !important;
        border-radius: 3rem !important;
        border: 1rem solid var(--highlight-primary);
        border-width: 0.1rem !important;
        border-color: var(--highlight-primary) !important;
        padding: 0.3rem 1rem !important;
        font-size: 1.4rem;
        color: var(--highlight-primary) !important;

        &::placeholder {
          color: var(--highlight-primary) !important;
        }
      }

      .css-t3ipsp-control {
        background-color: transparent !important;
        border-radius: 3rem !important;
        border: 1rem solid var(--highlight-primary);
        border-width: 0.1rem !important;
        border-color: var(--highlight-primary) !important;
        padding: 0.3rem 1rem !important;
        font-size: 1.4rem;
        color: var(--highlight-primary) !important;

        &::placeholder {
          color: var(--main-bg) !important;
        }
      }

      .css-1dimb5e-singleValue {
        color: var(--highlight-primary) !important;
        font-weight: 600;
      }

      .css-1jqq78o-placeholder {
        color: var(--highlight-primary) !important;
        font-weight: 600;
      }
    }

    .btn-cont {
      width: 100%;
      background-color: var(--main-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      border-radius: 0 0 1rem 1rem;
      padding: 1rem 0;

      @include devices(tablet) {
        flex-direction: column;
      }

      .submit-btn {
        background-color: var(--highlight-primary);
        border-radius: 4rem;
        outline: none !important;
        padding: 1rem 4rem;
        border: none !important;
        color: var(--text-white);
        font-size: 1.6rem;

        &:disabled {
          background-color: var(--bg-3) !important;
          color: var(--text-primary) !important;
        }
      }

      .cancel-btn {
        background-color: transparent;
        border-radius: 4rem;
        outline: none !important;
        padding: 1rem 4rem;
        border: 0.1rem solid var(--highlight-primary) !important;
        color: var(--highlight-primary);
        font-size: 1.6rem;
        margin-left: 1rem;

        @include devices(tablet) {
          margin-top: 1rem;
        }

        &:hover {
          background-color: var(--highlight-primary) !important;
          color: var(--text-white) !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  // .home-contents-cont {
  //   max-width: 1440px;
  //   margin: 0 auto;
  // }
}
