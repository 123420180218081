.footer-cont {
  background-color: var(--bg-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .first-cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    height: 90%;
    padding: 6rem 0;

    @include devices(tablet) {
      flex-direction: column;
      width: 100%;
      padding: 10rem 5rem;
    }

    .copyright-cont {
      width: 30%;
      display: flex;
      flex-direction: column;

      @include devices(tablet) {
        width: 100%;
        margin-bottom: 5rem;
      }

      .copyright {
        font-size: 2rem;
        margin-bottom: 1rem;
        letter-spacing: 0.2rem;
      }

      .cookies {
        font-size: 1.6rem;
        color: var(--text-secondary);
        line-height: 2.2rem;
        margin-top: 1.5rem;
      }
    }

    .link-cont {
      width: 20%;
      display: flex;
      flex-direction: column;

      @include devices(tablet) {
        width: 100%;
        margin-bottom: 5rem;
      }

      .header {
        letter-spacing: 0.3rem;
        font-size: 2rem;
        margin-bottom: 3rem;
      }

      .link {
        &:hover {
          color: var(--highlight-primary);
          transform: scale(1.1);
        }
        transition: 0.5s all;
        cursor: pointer;
        font-size: 1.6rem;
        color: var(--text-secondary);
        margin-bottom: 1rem;
      }
    }

    .contact-us {
      width: 30%;
      display: flex;
      flex-direction: column;

      @include devices(tablet) {
        width: 100%;
      }

      .header {
        letter-spacing: 0.3rem;
        font-size: 2rem;
        margin-bottom: 3rem;
      }

      .form-control {
        height: 5rem;
        width: 100%;
        border: 0.1rem solid var(--text-secondary);
        outline: none;
        padding: 0 2rem;
        font-size: 1.6rem;
        background-color: transparent;
        color: var(--text-primary);
      }
      .cookies {
        font-size: 1.4rem;
        color: var(--text-secondary);
        margin-top: 2rem;
      }
    }
  }

  .second-cont {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    border-top: 0.1rem solid var(--text-secondary);
    width: 85%;

    @include devices(tablet) {
      flex-direction: column;
      width: 100%;
      padding: 2rem;
    }

    .follow-us-cont {
      display: flex;
      align-items: center;

      .header {
        letter-spacing: 0.3rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        @include devices(tablet) {
          font-size: 1.2rem;
        }
      }

      .icon {
        font-size: 3rem;
        margin-left: 2rem;
        @include devices(tablet) {
          margin-left: 1rem;
        }
      }

      .link {
        transition: 0.5s all;
        cursor: pointer;
        font-size: 1.6rem;
        color: var(--text-secondary);
        margin-left: 2rem;

        @include devices(tablet) {
          margin-left: 1rem;
        }
        &:hover {
          color: var(--highlight-primary);
          transform: scale(1.1);
        }
      }
    }

    .designed-by {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      color: var(--text-secondary);
      @include devices(tablet) {
        margin-top: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  .first-cont {
    max-width: 1440px;
    margin: 0 auto;
  }

  .second-cont {
    max-width: 1440px;
    margin: 0 auto;
  }
}
