.masters-cont {
  .tabs-cont {
    border-bottom: 0.11rem solid var(--bg-1);
    display: flex;

    .tabs {
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 1rem;
      cursor: pointer;
      transition: 0.2s all;
      padding: 1rem;
      margin-left: 1rem;
    }

    .active-tabs {
      border-bottom: 0.15rem solid var(--gradient-1);
      color: var(--gradient-1);
      font-weight: 600;
    }
  }

  .master-contents-cont {
    margin-top: 2rem;
    padding: 0 2rem 4rem 2rem;

    .forms-cont {
      display: flex;
      margin: 1rem 0;
      align-items: flex-end;
      .form-group {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        width: 20rem;

        .warning {
          color: var(--highlight-error);
          margin: 1rem 0 0 1.5rem;
          font-size: 1.3rem;
        }

        @include devices(mobile) {
          width: 100% !important;
        }

        label {
          font-size: 1.3rem;
          margin-left: 1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: var(--highlight-primary);
        }

        .form-control {
          background-color: var(--white);
          border: 0.2rem solid var(--highlight-primary) !important;
          outline: none !important;
          padding: 1rem 2rem !important;
          font-size: 1.4rem;
          font-weight: 600;
          box-shadow: none !important;
          border-radius: 6rem;
          color: var(--highlight-primary);

          &::placeholder {
            color: var(--highlight-primary);
          }

          @include devices(mobile) {
            font-size: 2rem;
          }
        }
      }

      .button-add {
        background-color: var(--highlight-primary);
        border-radius: 2rem;
        outline: none !important;
        padding: 1rem 4rem;
        border: none !important;
        color: var(--text-white);
        margin-bottom: 0.5rem;
        margin-left: 1rem;
        font-size: 1.4rem;
      }
    }

    .table-cont {
      margin: 2rem 0;
      height: calc(100vh - 30rem);
      overflow: auto;
    }

    .table-cont-2 {
      width: 50%;
      margin: 4rem 0;
      height: 65vh;
      overflow: auto;
    }
  }
}
